<template>
  <v-card flat class="rounded-lg pa-2" height="100%">
    <v-card-title
      class="text-color-title body-1 font-weight-bold justify-space-between"
    >
      {{ title }}
      <create-badge-dialog></create-badge-dialog>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="tab">
        <v-tabs-slider color="#d4f1ff"></v-tabs-slider>
        <v-tab v-for="(tab, key) in tabs" :key="key" class="text-capitalize">
          {{ tab.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(tab, key) in tabs" :key="key">
          <div v-for="(item, key) in items" :key="key" class="my-4">
            <div class="mb-2">{{ item.title }} ({{ item.items.length }})</div>
            <div class="d-flex">
              <div
                v-for="(badge, badgeKey) in item.items"
                :key="badgeKey"
                class="mr-2"
              >
                <create-badge-dialog
                  :itemId="badge.id"
                  :badgeImageURL="badge.image_url"
                ></create-badge-dialog>
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { GLOBAL_EVENT_BUS } from "@/services/event_buses/globalEventBus";

import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
const badgesRepository = RepositoryFactory.get("badge");

const CreateBadgeDialog = () => import("../dialogs/CreateBadgeDialog.vue");

export default {
  components: {
    CreateBadgeDialog
  },
  data: () => ({
    title: "Badge Management",
    tab: null,
    cachedItems: [],
    items: [],
    tabs: [],
    eventName: "reloadItems_badge"
  }),
  watch: {
    tab: function(value) {
      let tab = this.tabs[value];

      if (tab.value === "all") {
        this.items = this.cachedItems;

        return;
      }

      this.items = this.cachedItems.filter(item => item.value === tab.value);

      return;
    }
  },
  methods: {
    async getData() {
      let params = {
        full_data: true
      };

      await badgesRepository
        .get({ params })
        .then(response => {
          let {
            data: { responseData: items }
          } = response;

          this.cachedItems = items;

          this.cachedItems = this.setBadgeGroupings(this.cachedItems);

          this.items = this.cachedItems;
        })
        .catch(error => {
          console.log({ error });
        });
    },
    getBadgeCategories() {
      this.tabs = [
        {
          id: 0,
          name: "All",
          value: "all"
        },
        ...badgesRepository.getCategories()
      ];
    },
    setBadgeGroupings(userBadges) {
      let categories = badgesRepository.getCategories();

      let items = [];

      categories.map(category => {
        let filterUserBadges = userBadges.filter(
          userBadge => userBadge.category === category.value
        );

        if (filterUserBadges && filterUserBadges.length > 0) {
          items.push({
            title: `${category.name}`,
            ...category
          });
          items[items.length - 1].items = [...filterUserBadges];
        }
      });

      return items;
    },
    reloadItemsBadgeEventBus() {
      GLOBAL_EVENT_BUS.$off(this.eventName);
      GLOBAL_EVENT_BUS.$on(this.eventName, () => {
        this.getData();
      });
    },
    setupEventBus() {
      this.reloadItemsBadgeEventBus();
    }
  },
  async mounted() {
    await this.getBadgeCategories();
    await this.getData();
    this.setupEventBus();
  }
};
</script>

<style lang="scss" scoped>
.badge-btn {
  margin-right: 20px;
  margin-bottom: 20px;
}
</style>
